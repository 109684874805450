<template>
  <div class="not-found">
    <div class="not-found__message">
      <svg-icon name="not-found" class="not-found__image"></svg-icon>
      <Heading level="3">{{ $t('not_found.mistake') }}</Heading>
      <div class="not-found__text">{{ $t('not_found.nothing_found') }}</div>
    </div>
    <ButtonBase
      size="m"
      color="blue"
      class="not-found__to-main-btn"
      @click.native="goToDashboard"
      >{{ $t('not_found.to_dashboard') }}</ButtonBase
    >
  </div>
</template>

<script>
import Heading from '@/elements/Heading/Heading.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

export default {
  components: { Heading, ButtonBase },
  methods: {
    goToDashboard() {
      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>

<style lang="sass" scoped>
.not-found
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 70px

.not-found__message
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  width: 600px
  height: 420px
  margin: 32px 0 40px
  background-color: var(--primary-color-white)

  @media screen and (min-width: 1199px)
    width: 650px
    height: 530px
    margin: 120px 0 40px

.not-found__image
  width: 260px
  height: 150px

.not-found__text
  font-weight: normal
  font-size: 28px
  line-height: 32px

.not-found__to-main-btn
  width: 420px
</style>
